import { addPropertyControls, ControlType } from "framer"
import React, { useEffect, useState } from "react"

const PublisherDisplay = ({ selectedPublisher }) => {
    const [data, setData] = useState([])
    const [publisher, setPublisher] = useState(null)

    useEffect(() => {
        //fetch("https://storage.googleapis.com/puls/other/bm/publishers2.json")
        fetch("https://puls-dev-3zydpp4yaa-ey.a.run.app/puls/publisherproducts")
            .then((response) => response.json())
            .then((apiData) => {
                setData(apiData)
                const pub = apiData.find((p) => p.name === selectedPublisher)
                setPublisher(pub)
            })
            .catch((error) => console.error("Error fetching data:", error))
    }, [selectedPublisher])

    if (!publisher) {
        return <div>Loading...</div>
    }

    return (
        <div
            style={{
                borderRadius: "8px",
                backgroundColor: "#fff",
                color: "#222",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                }}
            >
                <img
                    src={publisher.icon}
                    alt={`${publisher.name} icon`}
                    style={{
                        marginRight: "10px",
                        borderRadius: "2px",
                        height: "22px",
                        width: "22px",
                    }}
                />
                <h2 style={{ fontFamily: "Open Sans", margin: 0 }}>
                    {publisher.name}
                </h2>
                <span
                    style={{
                        marginLeft: "10px",
                        marginTop: "4px",
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        color: "#777",
                    }}
                >
                    {publisher.platform}
                </span>
            </div>
            <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    fontFamily: "Open Sans",
                }}
            >
                <thead>
                    <tr>
                        <th style={{ textAlign: "left", paddingBottom: "8px" }}>
                            Pláss
                        </th>
                        <th style={{ textAlign: "left", paddingBottom: "8px" }}>
                            Stærð
                        </th>
                        <th
                            style={{ textAlign: "right", paddingBottom: "8px" }}
                        >
                            CPM
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {publisher.placements.map((placement, index) => (
                        <tr
                            key={index}
                            style={{
                                borderTop: "1px solid #f2f2f2",
                                borderBottom: "1px solid #f2f2f2",
                            }}
                        >
                            <td style={{ padding: "8px 0" }}>
                                {placement.name}
                            </td>
                            <td>{placement.size}</td>
                            <td
                                style={{
                                    textAlign: "right",
                                    paddingBottom: "8px",
                                }}
                            >
                                {placement.cpm} kr.
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export function PublisherComponent(props) {
    return <PublisherDisplay {...props} />
}

addPropertyControls(PublisherComponent, {
    selectedPublisher: {
        type: ControlType.String,
        title: "Publisher",
        defaultValue: "Vísir",
    },
})

PublisherComponent.defaultProps = {
    height: 300,
    width: 400,
    selectedPublisher: "Vísir",
}
